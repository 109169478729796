import styled from 'styled-components';

export const Copyrights = styled.div`
  color: ${props => props.theme.colors.kelp};
  font-size: 1.4rem;
  font-weight: normal;
`;

export const Logos = styled.div`
  margin-bottom: 4rem;
`;

export const Supported = styled.h3`
  color: ${props => props.theme.colors.kelp};
  font-size: 2rem;
  font-weight: normal;
  font-family: IM Fell English, serif;
  margin-bottom: 2rem;
`;

export const logo1Css = `
  max-width: 13.9rem;
`;

export const logo2Css = `
  max-width: 15.4rem;
`;
