import ArrowRightIcon from '../../assets/arrow-right-link.inline.svg';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: block;
  margin-bottom: 6.6rem;
`;

const Title = styled.h3`
  color: #fff;
  font-size: 3rem;
  font-weight: normal;
  font-family: Barlow, sans-serif;
  margin-bottom: 0.2rem;
`;

const Subtitle = styled.div`
  color: ${props => props.theme.colors.schist};
  font-size: 1.4rem;
  font-family: Barlow, sans-serif;
  font-weight: normal;
  margin-bottom: 1rem;

  strong {
    font-weight: bold;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.meteor};
  font-size: 1.4rem;
  font-family: Barlow, sans-serif;
  font-weight: bold;
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-left: 0.5rem;
`;

const FooterLink = ({ title, subtitle, url }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Link rel="noopener noreferrer" target="_blank" href={url}>
        Website <StyledArrowRightIcon />
      </Link>
    </Container>
  );
};

FooterLink.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
};

export default FooterLink;
