import bg1 from '../images/l01.png';
import bg2 from '../images/l02.png';
import styled from 'styled-components';

const BackgroundLayer = styled.div`
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  pointer-events: none;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  backface-visibility: inherit;

  @media ${props => props.theme.breakpoints.lg} {
    display: inline-block;
  }
`;

export const BackgroundLayer1 = styled(BackgroundLayer)`
  background: url(${bg1});
  z-index: 9000;
  transform: translated3d(0, 0, 0);
`;

export const BackgroundLayer2 = styled(BackgroundLayer)`
  background: url(${bg2});
  z-index: 9001;
  transform: rotate(15deg);
`;
