/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import * as Styled from './styled';

import { StaticQuery, graphql } from 'gatsby';

import Footer from './Footer';
import Header from './Header';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const LayoutContainer = styled.div`
  color: #fff;
  position: relative;
`;

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          {/* <Styled.BackgroundLayer1 /> */}
          {/* <Styled.BackgroundLayer2 /> */}
          <LayoutContainer>
            <Header siteTitle={data.site.siteMetadata.title} />
            {children}
            <Footer siteTitle={data.site.siteMetadata.title} />
          </LayoutContainer>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
